@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
}

body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

img.loading-icon {
  animation: loadingIcon 1s infinite reverse linear;
}

@keyframes loadingIcon {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}


img.alert-circle {
  position: absolute;
  right: 10px;
}
